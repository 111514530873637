<template>

  <v-select
      @change="change"

      v-model="user2"
      label="Юзер Обм."
      :items="users"

      :item-text="item => item.username "
      return-object
      outlined
      clearable
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "ExchangeUserComboboxMerchant",

  props: [ 'user'],

  data: function () {
    return {
      user2:null,
      users:[],
    }
  },

  apollo: {
    $subscribe: {
      users: {
        query: gql`
query MyQuery {
  users(where: {username: {_like: "noros_exchange_%"}}) {
    username
    id
  }
}
`,
        result({data}) {
          this.users = data.users;
        },
      },
    },
  },

  mounted() {
    this.user2 = this.user
  },

  methods: {
    change(val) {
      this.$emit('update:user', val)
    }
  },
}
</script>